import React, { useState, useEffect } from 'react'
import BorderBox from '../../components/BorderBox'
import { Box, Button, FormGroup, Grid, IconButton, MenuItem, Modal, Typography } from '@mui/material'
import DropdownInput from '../../components/DropdownInput'
import AddStop from '../Summary/AddStop'
import ArrivalDetails from './ArrivalDetails'
import ViewMap from './ViewMap'
import { AddCircle, Close } from '@mui/icons-material'
import AutoComplete from '../../components/AutoComplete'
import InputField from '../../components/InputField'
import reactotron from '../../ReactronConfig'

const VehicleDetails = ({ control, errors, vehicles, setValue, getValues, selectedVehicle, vehicleChange, stopsChanged, bookingData }) => {

    reactotron.log({selectedVehicle})

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: '25px',
        border: '1px solid #fff',
        p: 4,
        // height: '671'
    };

    const [addStop, setAddStop] = useState(false)
    const [stops, setStops] = useState(bookingData?.stops)
    const [stopName, setStopName] = useState([])
    const [stopNote, setStopNote] = useState(null)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
      setStops(bookingData?.stops)
    }, [bookingData?.stops?.length])
    


    


    useEffect(() => {
        stopsChanged(stops)
    }, [stops?.length])
    
    

    const handleClose = () => {
        setAddStop(false)
    }

    const handleOpen = () => {
        setAddStop(true)
    }


    const addStops = () => {
        let stop = {
            ...stopName,
            cityname: stopName?.description,
        }
        handleClose()
        setStops(prev => prev ? [...prev, { stopName: stop, stopNote }] : [{ stopName: stop, stopNote }])
        

    }


    useEffect(() => {
        setValue("stops", stops)
    }, [stops])


    const removeStop = (index) => {
        stops.splice(index, 1)
        setStops([...stops])
    }
    

    return (
        <>
        <BorderBox title={"Vehicle Details"}>
            <div style={{ position: 'absolute', right: 0,  top: -10 }}>
                    <ViewMap />
                </div>
            <Grid container spacing={.5} >
                
                <Grid item xs={12} md={12} sm={12} sx={{ flexGrow: 1 }}>
                    <DropdownInput
                        fieldLabel="Vehicle"
                        fieldName="vehicle"
                        control={control}
                        error={errors.vehicle}
                        values={selectedVehicle ? selectedVehicle : null}
                        changeValue={(e) => {
                            vehicleChange(e.target?.value)

                        }}
                    >
                        <MenuItem disabled value="" className='formText'>
                            Select
                        </MenuItem>
                        {vehicles && vehicles?.map((opt, index) => (
                            <MenuItem className='formText' selected={opt?.id === selectedVehicle ? true : false} value={opt?.id}>{`${opt?.vehicleType} (${opt?.total})`}</MenuItem>
                        ))}
                    </DropdownInput>
                </Grid>
                <Grid item xs={12} md={6} sm={12} sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                    <IconButton style={{ padding: 0, margin: 0 }} sx={{ "&:hover": { borderRadius: '15px' } }}>
                        <div onClick={handleOpen} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', cursor: 'pointer' }} >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <span>
                                    <AddCircle sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1 }} style={{ color: '#B6A269', fontSize: 30 }} />
                                </span>
                                <span>
                                    <Typography className='addtext' >Additional Stops?</Typography>
                                </span>

                            </div>
                        </div> 
                    </IconButton>
                </Grid>
                
            </Grid>
            <Grid container  spacing={.5}>
                <Grid item xs={12} md={12} sx={{ flexGrow: 1, marginTop: 3 }} >
                    {stops?.map((stop, index) => (
                        <Box padding={0.5} paddingX={2}  borderRadius={0} margin={0.5} position={"relative"} sx={{
                            border: '1px solid grey'
                            
                        }}>{stop?.stopName?.address}
                        <IconButton onClick={() => removeStop(index)}  style={{ position: 'absolute', right: 10, top: -5 }}>
                        <Close style={{ color: 'red' }}  />
                        </IconButton>
                        </Box>
                    ))}
                </Grid>
            </Grid>
        </BorderBox>
        <Modal
                open={addStop}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} width={{ xs: '80%', sm: '80%', md: "55%", lg: "45%", xl: "30%" }}>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {/* <Typography id="modal-modal-title" className='modalHeader' flex={0.95} display="flex" justifyContent="center">
                            ADD STOPS
                        </Typography> */}
                        <IconButton onClick={handleClose} >
                            <Close />
                        </IconButton>
                    </div>
                    <BorderBox title={"ADD STOPS"}>
                            <FormGroup >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                        <AutoComplete
                                            placeholder="Stop Name"
                                            fieldName="stop_name"
                                            control={control}
                                            error={errors?.stop_name}
                                            onPlaceChange={(value) => {
                                                setStopName(value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                                        <InputField
                                            fieldLabel="Add Notes"
                                            fieldName="stopNote"
                                            control={control}
                                            error={errors.stopNote}
                                            width='100%'
                                            fontSize="14px"
                                            fieldChange={(e) => {
                                                setStopNote(e.target.value)
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 20 }}>
                                    <Button type="submit" variant="filled" size='small' 
                                    onClick={addStops}
                                    style={{ backgroundColor: '#333333', borderRadius: 0, padding: 2, }}>
                                        <Typography className='buttonText'>ADD</Typography></Button>
                                </div>
                            </FormGroup>
                    </BorderBox>
                </Box>

            </Modal>
        </>
    )
}

export default VehicleDetails